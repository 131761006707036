<template>
  <Blog />
  <Footer class="beige-bcg"/>
</template>

<script>
  import Blog from '../components/concept/Blog'
  import Footer from '../components/Footer'

  export default {
    components: { Blog, Footer },
    name: 'Konsept - Lowenstad Branding',
    created(){
        document.title = "Konsept - Lowenstad Branding"
    }
  }
</script>

<style lang="scss" scoped>

</style>