<template>
  <ContactOne />
  <Footer class="beige-bcg"/>
</template>

<script>
  import ContactOne from '../components/contact/ContactOne'
  import Footer from '../components/Footer'

  export default {
    components: { ContactOne, Footer },
    name: 'Kontakt Lowenstad Branding',
    created(){
        document.title = "Kontakt Lowenstad Branding"
    }
  }
</script>

<style lang="scss" scoped>

</style>