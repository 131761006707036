<template>
  <div class="top-container-sub color">
    <div class="center-horizontal max-width-l top-height-content-start">
      <div class="home-container">

        <div class="cookie-container">

          <h2>Cookie policy for Lowenstad</h2>
          <p class="cookie-text">
            Cookies er informasjonskapsler som lagrer informasjon om en bruker og sporer brukerens aktivitet på en nettside. 
            Lowenstad bruker cookies for at brukeropplevelsen på nettsiden vår kan forbedres og for å oppdage mangler eller feil.
            <br><br>
            Cookies som brukes på vår nettside er knyttet til å analysere hvordan brukeren ferdes på nettsiden vår og
            hvor trafikken på vår nettside kommer fra. Analyseverktøy som brukes er Google Analytics.
            Informasjonen som lagres i denne forbindelse er anonym og spores ikke direkte til hver enkelt bruker, 
            men gir et overordnet bilde av brukeradferden på vår nettside. 
            <br><br>
            Du kan reservere deg mot cookies ved å endre innstillingene i din nettleser. Praksisen her er forskjellig ettersom
            hvilken nettleser du bruker, men infromasjon om dette skal finnes på nettleserens hjelpesider.
            Vær oppmerksom på at ved å slå av bruken av infromasjonskapsler kan også visse funksjoner og funksjonalitet 
            bli borte. Brukeropplevelsen kan derfor bli påvirket.
          </p>

        </div>
                
      </div>
    </div>
  </div>
</template>

<script>
  
</script>

<style lang="scss" scoped>
  @import '../../styles/global.scss';

    .height {
      padding-top: 60px;
      padding-bottom: 60px;
      @include mobile-l {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }

    .home-container {
      height: auto;
      width: 100%;
      margin: auto;
      min-height: 70vh;
    }

    .color {
      background: $cream;
    }

    .cookie-container {
      padding-bottom: 50px;
      @include tablet {
        padding: 50px 0px 50px 0px;
      }
    }

    .cookie-text {
      padding-top: 30px;
    }


</style>