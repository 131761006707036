let posts = [
    {   
        title: "The golden ratio", 
        id: 24, 
        slug: "golden-ratio", 
        description: 
            `The golden ratio is a mathematical measure that frequently appears organically in nature. The dimensions are said to be pleasing to the eye and have therefore been used in art, architecture and design as it creates a composition that looks proportional and balanced. The golden ratio has been used by the ancient Greeks when building their temples as well as Leonardo Da Vinci creating his masterpieces. All inspired by the ratio found in sea shells, flowers, spider webs, sea urchins, ocean waves, and even in the human body.
            
            Design and creativity often comes from something else than a mathematical equation, however, some rules or guides are good to have in mind when developing a masterpiece as well as a website. Wireframes, user interface, style guides... A web design needs a set of rules to have a coherent style that is easy to understand and interact with for the user. All so your potential customer gets a good user experience when visiting your website, understand your product and do not fall off in the process.
            `, 
        img: require('../../../assets/img/blog-posts/post-24.jpg') 
    },
    {   
        title: "Building blocks", 
        id: 23, 
        slug: "building-blocks", 
        description: 
            `I designed this logo for Profag a couple of years ago. Their floor technology is really progressive, so I wanted their brand to have the same innovative and forward-looking feel about it. The result was a modern and sharp design that resembles building blocks - pieces that fit together, just like their easy to lay, click floors do.

            Reflecting a company's product in their logo can be an easy way to spot what a company do. However, a logo does not have to be a drawing of the actual product, but should rather reflect the strengths of the product and show who the company is. It is therefore important to understand each company we develop brand material for, what their mission is, what they envision for themselves, what their product's strengths are, and who their customers are. We can then use this knowledge to develop a logo that reflects each company individually and will stand with them into their future.
            `, 
        img: require('../../../assets/img/blog-posts/post-23.jpg') 
    },
    {   
        title: "Branding and web", 
        id: 22, 
        slug: "branding-web", 
        description: 
            `Why not launch your new branding and website this Autumn? You lay back on the sun-bed while Lowenstad is a busy bee making your company bloom 🐝 🌺

            After the holidays many come back to work with new ideas and a drive for difference. Maybe you see a need to expand or develop your company? We can help you pin your vision down and create the framework you need to make it happen. We can show you how you can differentiate your company, market yourself and grow your audience. With experience in marketing, trends, design and web, Lowenstad can develop all you need to brand yourself the way you should to reach your goals.
            `, 
        img: require('../../../assets/img/blog-posts/post-22.jpg') 
    },
    {   
        title: "Secondary logo", 
        id: 21, 
        slug: "secondary-logo", 
        description: `As part of Lowenstad's logo range is our secondary logo. A secondary logo is necessary in cases where your main logo is too large to use. It is a simplified version of your main logo that still convey your full business name, but takes less space. Often, the main logo is horizontal, which is great for a desktop screen, while a secondary logo is vertical to fit mobile screens or narrow designs better.`, 
        img: require('../../../assets/img/blog-posts/post-21.jpg') 
    },
    {   
        title: "Modern logo", 
        id: 20, 
        slug: "modern-logo", 
        description: "I find that a lot of my customers want a simple, sleek and modern logo. The challenge is then to differentiate it from other logos out there as you have fewer details to work with. One way of making a modern logo stand out is by mixing fonts, creating a custom-made font or editing a font. This is how I designed Boru's logo, which is clean but still have character and personality.", 
        img: require('../../../assets/img/blog-posts/post-20.jpg') 
    },
    {   
        title: "Dare to dream", 
        id: 19, 
        slug: "dare-to-dream", 
        description: "Many have the ideas, passion and dream to start their own company. The biggest obstacle however, is usually the fear of failure and going into something unstable and unknown. But dreams aren’t reached without taking a bit of risk and having some courage. So dare to dream and be brave enough to chase your passions.", 
        img: require('../../../assets/img/blog-posts/post-19.jpg') 
    },
    {   
        title: "Steps of creating a brand", 
        id: 18, 
        slug: "steps-of-creating-a-brand", 
        description: 
            `When creating a brand, there are certain points that should be considered to ensure a coherent style that reflects your company.
            
            Concept:
            Shortly presents your company’s main idea.
            
            Vision:
            What do you want to accomplish for your customers and what do you reach for, imagine and dream for your company to be?

            Motto:
            A motto should convey your company’s purpose in a sentence.

            Goal:
            In the big picture, what are your company’s defined goals and objectives in a business sense?

            Essence:
            in a few words or a short sentence - what defines your brand?

            Personality:
            Think of your company as a person, which traits and characteristics would your brand have? Consider your customer as the brand personality should be a way to connect emotionally with your target consumer.

            Values:
            Which meaningful points would you like to guide your business? Beliefs that your business should reach for, live by and stand for.

            Target Audience:
            Who do you think would buy your products or services? Where do they live, which lifestyle do they have, what’s their income, do they have a family? You need to be able to describe the person and group of people you aim to reach with your service or product. In this way you can build your brand and market your company, so it connects with your target customer on a personal level that connects directly to their wants and needs.

            Position:
            Consider your products main selling points. Should you focus on quality, price, uniqueness service..? With that in mind, where should you position your brand in the marketplace?
            `, 
        img: require('../../../assets/img/blog-posts/post-18.jpg') 
    },
    {   
        title: "Shades of Beige", 
        id: 17, 
        slug: "shades-of-beige", 
        description: 
            `I feel a bit blend and boring when I say my favourite colour is beige. However, I find it the most elegant, soothing and calm colour there is. Also, it matches with all other colours if you get the tone right - which can be a bit of a struggle. I spent a lot of time getting it right for my website, which is all beige - have you noticed? 
            
            The thing I love about beige is the colour range you can get. It can go all the way from cream to a warm, almost orange colour, to a colder, grayish sophisticated light brown tone. So it’s a great colour if you want to capture and play with light and shade, like I have done on the background of my homepage.`, 
        img: require('../../../assets/img/blog-posts/post-17.jpg') 
    },
    {   
        title: "Finesse of the classic", 
        id: 16, 
        slug: "classic-finesse", 
        description: 
            `I love the finesse of the classic, antique buildings - and try to draw that craftsmanship and detailing into my designs. Creating the Lowenstad brand, I took inspiration from antique prints, stamps and ceiling features, but with a modern twist. I also mixed classic and modern when creating Boru’s brand. The B seen here is used as decoration on the website and as a favicon. Boru’s design is sharp and clean, but by adding some softer, classic shapes the brand come across more friendly, steady and interesting. 
        
            Check out the website at: https://boru.no`, 
        img: require('../../../assets/img/blog-posts/post-16.jpg') 
    },
    {   
        title: "Observe your surroundings", 
        id: 15, 
        slug: "observe-your-surroundings", 
        description: `Observe your surroundings - our greatest tip for creating creativity! Look around you, soak in the details of buildings, people, commercials, clothes, interior. Everything around you is a design of some sort - either by nature or by man. Working with design, either graphic design or web design, getting inspired is an important part of the process. Nothing gets created without an idea or spark.`, 
        img: require('../../../assets/img/blog-posts/post-15.jpg') 
    },
    {   
        title: "WE JUST LOWE BRANDING", 
        id: 14, 
        slug: "lowe-branding", 
        description: "Personality and style often go hand-in-hand. You can see this when travelling from one part of the city to another. In London, going from Sloane Square, stopping by Battersea, dropping by Camden, before ending up in Shoreditch, you will definitely see a style change. We human beings are quite simple in that way, we seek those who we fit in with - which have similar interests and wants as us. This results in different areas in a city which speak to different groups of people - businesses that are eager to satisfy the needs of those living in their neighbourhood. Considering your customer and where that customer live, which interests they have and what their future plans are, are therefore very important to pin down. To put it to the extreme, a Ralph Lauren store might not work as well in Shoreditch as it would in Sloane Square, while a men's barbershop would most likely do better in Shoreditch where beards have been in fashion for a long while. It is therefore important get your customer right, get to know them, and follow the direction - or even better - create the direction they're going. Be sure to offer them what they want, inspire them, and be inspired by them. Knowing your customer and have a brand identity that speaks to them personally, is what branding is all about.", 
        img: require('../../../assets/img/blog-posts/post-14.jpg') 
    },
    {   
        title: "The beauty of nature", 
        id: 13, 
        slug: "beauty-of-nature", 
        description: "The beauty of nature never ceases to amaze me. Even something as simple as lines… Lines in stones, in the sand, in wood, in leafs or in a spiderweb. If you look around there is always something to draw inspiration from. So get out there and soak up all the beauty nature has to give - take the time to appreciate it and what this season has to offer.", 
        img: require('../../../assets/img/blog-posts/post-13.jpg') 
    },
    {   
        title: "Trade of a logo", 
        id: 12, 
        slug: "trade-logo", 
        description: "To easily understand what a company does it might be an idea to use their trade in their logo. However, as this has been done through all times a logo can easily look outdated or similar to what has been seen before. I there spent a lot of time trying out different styles and brushes when creating a new logo for Aanestad. A company that paint interior and exterior of homes. They are experts in what they do and have an upscale clientele, so they wanted this reflected in their logo. I took inspiration in paint strokes, in art and in the interior industry. Trying to move away from the masculine and tough building industry and create a more classic and soft look that was still up to date.", 
        img: require('../../../assets/img/blog-posts/post-12.jpg') 
    },
    {   
        title: "Close-up imagery", 
        id: 11, 
        slug: "close-up-imagery", 
        description: "I love using close-up imagery in my designs. I feel it creates a more artistic look that drives curiosity and makes one wonder. It also gives a feel and a vibe to the viewer rather than just showing a straight up picture of an item. It forces the viewer to think rather than telling them exactly what it is. This also connects to the viewers feelings and makes the design more interesting. This image was used for BConcept, do you see that it’s a close-up of a leaf? BConcept work with wooden floors, so much of their identity is based on Norwegian woods and trees.", 
        img: require('../../../assets/img/blog-posts/post-11.jpg') 
    },
    {   
        title: "BConcept website", 
        id: 10, 
        slug: "bconcept-website", 
        description: "Have a look at https://www.bconcept.no, where we created the BConcept website. Taking inspiration from Norwegian nature, wooden details and a cold climate. We also considered BConcept’s modern, forward looking and sustainable approach. Straight lines mixed with graphic imaging and a fluid design.", 
        img: require('../../../assets/img/blog-posts/post-10.jpg') 
    },
    {   
        title: "Lowenstad Branding", 
        id: 9, 
        slug: "lowenstad-branding", 
        description: "A main logo should contain your whole business name, and often also the industry your company's in. The Lowenstad main logo contains the full business name as well as the industry we operate in. As a recognisable touch to our brand the lion submark in integrated in the design and can be seen throughout our whole logo range.", 
        img: require('../../../assets/img/blog-posts/post-9.jpg') 
    },
    {   
        title: "Print Play", 
        id: 8, 
        slug: "print-play", 
        description: "Playing around with drawings can create interesting and stylish results. Even just copy-pasting a palm tree upside down, and it creates something unique, as the drawing isn't simply reproduced. Seeing things in a new way, daring to manipulate the normal, and testing and daring to fail is an important part of the creative process. Doing this can help you see things in a different way, it can create new ideas and helps you get out of your comfort zone and out of the box you usually work inside. This often results in work that are interesting, new, fresh and unique rather than just nice. So dare to play - and have fun with it!", 
        img: require('../../../assets/img/blog-posts/post-8.jpg') 
    },
    {   
        title: "Exotic Summer prints", 
        id: 7, 
        slug: "exotic-summer-prints", 
        description: "We adore exotic Summer prints and textures. The faded, old style, printed look of this is exactly what can give some personality and depth to a modern design. Dreaming of the beach and the shadow of the palm leaves on white sand… We will definitely be working with some prints and textures this Summer.", 
        img: require('../../../assets/img/blog-posts/post-7.jpg') 
    },
    {   
        title: "From a seed to a full grown tree", 
        id: 6, 
        slug: "seed-to-tree", 
        description: 
            `From a seed to a full grown tree. Being part of creating the BConcept brand profile, from idea to an established business, have been a great adventure. 
            
            We have worked closely with their CEO Caja Basberg on creating their whole image, including style guide, brand profile, logo, website, business cards, brochures and presentations. Proud to work with a local brand with focus on nature and the environment while creating quality wooden floors that stands the test of time - and even cold Norwegian winters.`, 
        img: require('../../../assets/img/blog-posts/post-6.jpg') 
    },
    {   
        title: "Waves of light", 
        id: 5, 
        slug: "waves-of-light", 
        description: 
            `It is fascinating how artists manage to capture light in paintings. That a flat canvas of paint can radiate and look like the sun is coming through it. Being inspired by this, Lowenstad has based its colour palette on light. Beige tones, shades and a front page that seem to reflect light. Many effects can be used on a website, but playing with light and shade is always an elegant way of creating an interesting result. It also makes the website seem more life like, as it creates a 3D effect and don't seem flat. 

            Using light in a website is fortunately not as difficult as painting light, and it does not take a lifetime to learn. Building a website you can also use movement, which makes the process easier. But even though it's easier to create it still creates a mesmerising effect which is lively, warm and most importantly; fetches the visitor - which is one of the main points when creating a website. Manage to make a visitor stay on your site and actually be interested enough to check out your business is critical for a website to achieve. It also effect your SEO as you get better rating the longer each of your visitors stay on your site. Creating a clear message and fetching over-fold content is therefore crucial and in our case light helps us achieve this.
            `, 
        img: require('../../../assets/img/blog-posts/post-5-2.jpg') 
    },
    {   
        title: "Setting the right tone", 
        id: 4, 
        slug: "setting-the-right-tone", 
        description: 
        `In a jungle of screaming brands be the one who hits the right tone and stands out roaring!

        Many goes wrong when building their brand as they don't have a strategy for their visual presentation. They might get a logo, then a standard website, then start their social media marketing by adding some news, pictures of their products or write a bit about what they do. However, they do not see their platform as one whole and the communication vary. Smaller companies might not have the resources to hire someone, don't see the need for it, or don't have the time for it. However, it becomes more and more important to have a coherent brand. Most of it comes down to trust. If a company differ from one platform to the next, or have a bad presentation of themselves, it can be difficult to take the business seriously or understand who they are. It might seem like you are outdated and don't care, which reflects on your company as a whole. It doesn't have to be a big job either, the most important job is pinning down your style guide and some rules to follow for your marketing so it looks similar and have the same tone of communication. Always think quality over quantity. Depending on your business you might not have to be super active on your social media either, many just need to have a presence so that possible customers can see what you do and that you look serious and trustworthy.
        
        If you still think this seems a bit difficult or you don't have the time or interest, we in Lowenstad love and breathes for this, so why not contact us and we can figure it all out for you and get your company branding sorted out.
        `, 
        img: require('../../../assets/img/blog-posts/post-4.jpg') 
    },
    {   title: "Contrasts", 
        id: 3, 
        slug: "contrasts", 
        description: "Combining the classic with the modern is a great way to seem up-to-date but still get a timeless look. Combing contrasts are a good way to create balance and a sophisticated look that still feels fresh and fun. Either it is combining the elegant with the sassy, the peaceful with the loud, or the linear with the organic. Even contrasts in colour create a great effect if you want to emphasise something and make it stand out. Hence why we have green and red traffic lights, as they are contrasts and therefore differ. However, as a base, tone-in-tone and earthly tones are usually the way to go - but some contrasts which leads the eye to what is important is great. The Lowenstad brand also uses contrasts and is created using a combination of modern and classic, and black and white for our logo.", 
        img: require('../../../assets/img/blog-posts/post-3.jpg') 
    },
    {   title: "Inspiring Summer", 
        id: 2, 
        slug: "inspiring-summer", 
        description: 
            `What do you find inspirational? Having the Summer holiday ahead travelling gives me a lot of creative refill. It’s the feel, the smell, the touch and all the new experiences. Also the textures from a shell and the sand, or the shape of the waves. And of course the culture; the people, architecture, food, fashion and interior. 
        
            On my recent trip to LA I was so inspired by the interior at the Santa Monica Proper Hotel, everything being so warm, chic and relaxed. You should definitely take the trip and also go by the rooftop at the amazing Calabra restaurant where everything was on point! From the food and drinks to the style, view and the outfits of the people working there - Lowing it.`, 
        img: require('../../../assets/img/blog-posts/post-2.jpg') 
    },
    {   title: "Lowenstad logo range", 
        id: 1, 
        slug: "lowenstad-logo-range", 
        description: "When creating a logo you should consider the different arenas the logo is going to be used in. A logo is the main way for consumers to recognise your brand and should be easy to spot. Using your whole named logo might not work for a social media icon or a favicon in your browser, it is therefore essential to create different versions you can use at different platforms. May it be for decore, as a cover photo, stamp, or as a main logo stating your whole business name. Here is Lowenstad’s logo range!", 
        img: require('../../../assets/img/blog-posts/post-1.jpg') 
    }
]
export default posts
