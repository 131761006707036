<template>
  <div class="top-container-sub color">
    <div class="center-horizontal max-width-m top-height-content-start">
      <div class="home-container">
            <h1>Priser</h1>
            <div class="text-container">

                  <p class="text-one">
                    Lowenstad er et designbyrå som utvikler merkevare og nettsider spesielt for hver kunde 
                    individuelt. Ditt firma, produkt, målsetning, kundegruppe og identitet blir vurdert for 
                    alt som blir utviklet. Du vil derfor få et unikt produkt tilpasset ditt firma. 
                    <br><br>
                    Se priser for:
                  </p>

                  <div class="flex-row margin-between-sections price-section">
                    <div class="price-container">
                      <h2 @click="showWebsitePrice" id="web" class="active-price">Nettside</h2>
                    </div>
                    <div class="price-container">
                      <h2 @click="showBrandingPrice" id="brand">Merkevare</h2>
                    </div>
                    <div class="price-container">
                      <h2 @click="showGraphicPrice" id="graphic">Grafisk</h2>
                    </div>
                  </div>

                  <div class="price-detail-container">
                    <div v-if="showWebsitePrices"> <WebPrices /> </div>
                    <div v-if="showBrandingPrices"> <BrandPrices /> </div>
                    <div v-if="showGraphicPrices"> <GraphicPrices /> </div>
                  </div>

                
                  <p class="text-one">
                    Prisene oppgitt er en estimert pris uten mva. Prisen kan variere etter prosjektets størrelse og 
                    hva du selv har klart av materiale. Prisen gjelder ikke kost for abonnementer, domener eller print 
                    tilknyttet hvert enkelt prosjekt. Ta kontakt for eksakt pris for ditt prosjekt eller pakketilbud 
                    om du trenger flere tjenester. Spør gjerne om det er noe utenom tjenestene over du leter etter. 
                    Vi tilbyr også alt fra print på klær, plakater, bannere m.m. 
                  </p>

                  <router-link :to="{ name: 'Contact' }" class="button prices-one-button">
                    <button>
                      <div class="button-text">kontakt oss</div>
                    </button>
                  </router-link>
                
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import WebPrices from './prices/WebPrices.vue'
  import BrandPrices from './prices/BrandPrices.vue'
  import GraphicPrices from './prices/GraphicPrices.vue'

  export default {
    components: { WebPrices, BrandPrices, GraphicPrices },

    data() {
        return{
            showWebsitePrices: true,
            showBrandingPrices: false,
            showGraphicPrices: false,
        }
    },
    methods: {
      showWebsitePrice() {        
        this.showWebsitePrices = true;
        this.showBrandingPrices = false;
        this.showGraphicPrices = false;
        document.getElementById("web").classList.add("active-price")
        document.getElementById("brand").classList.remove("active-price")
        document.getElementById("graphic").classList.remove("active-price")
      },
      showBrandingPrice() {
        this.showBrandingPrices = true;
        this.showGraphicPrices = false;
        this.showWebsitePrices = false;
        document.getElementById("brand").classList.add("active-price")
        document.getElementById("web").classList.remove("active-price")
        document.getElementById("graphic").classList.remove("active-price")
      },
      showGraphicPrice() {
        this.showGraphicPrices = true;
        this.showWebsitePrices = false;
        this.showBrandingPrices = false;
        document.getElementById("graphic").classList.add("active-price")
        document.getElementById("brand").classList.remove("active-price")
        document.getElementById("web").classList.remove("active-price")
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../styles/global.scss';

    .height {
      padding-top: 60px;
      padding-bottom: 60px;
      @include mobile-l {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }

    .home-container {
      height: auto;
      width: 100%;
      margin: auto;
    }

    .color {
      background: $cream;
    }

    h1 {
      font-size: 40px;
      text-align: left;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-weight: 100;
      @include mobile-l {
        font-size: 60px;
      }
    }

    h2 {
      margin-bottom: 10px;
      color: $dark;
      font-size: 20px;
      font-weight: 600;
      @include mobile-s {
        font-size: 22px;
      }
      @include mobile {
        font-size: 26px;
      }
      @include mobile-l {
        font-size: 30px;
        font-weight: 100;
      }
      @include tablet {
        font-size: 46px;
      }
      &:hover {
        color: $black;
      }
    }

    .text-one {
      width: 100%;
      font-size: 20px;
      @include desktop-s {
        width: 68%;
      }
    }

    .prices-one-button button{
      margin-top: 50px;
      margin-bottom: 100px;
    }

    .text-container {
        height: 100%;
        width: 100%;
        display: inline;
        //width: 800px;
        overflow: hidden;
    }

    #web {
      text-align: left;
      float: left;
      cursor: pointer;
      @include mobile-l {
        width: 106px;
      }
      @include tablet {
        width: 162px;
      }
    }
    #brand {
      text-align: center;
      margin: auto;
      cursor: pointer;
      @include mobile-l {
        width: 140px;
      }
      @include tablet {
        width: 216px;
      }
    }
    #graphic {
      text-align: right;
      float: right;
      cursor: pointer;
      @include mobile-l {
        width: 94px;
      }
      @include tablet {
        width: 142px;
      }
    }
    .price-container {
      flex: 1;
      @include tablet {
        height: 110px;
      }
    }
    .active-price {
      border-bottom: 2px solid $attention;
      width: auto;
      color: $black;
    }

    .price-detail-container {
      margin-bottom: 100px;
    }

</style>

<style lang="scss">
  @import '../../styles/global.scss';

    /* PRICES */
    .prices-container {
      margin-bottom: 50px;
      margin-top: 50px;
      @include smaller-than-tablet{
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: 65% 35%;
      }
      @include tablet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .prices-product {
      @include smaller-than-tablet{
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        align-self: center;
        justify-self: left;
      }
      @include tablet {
        width: 176px;
        font-size: 31px;
      }
      @include desktop-s {
        width: 250px;
        font-size: 38px;
      }
      @include desktop {
        width: 300px;
        font-size: 40px;
      }
    }
    .prices-price {
      width: auto;
      @include smaller-than-tablet{
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        align-self: center;
        justify-self: right;
      }
      @include tablet {
        width: 120px;
      }
      p {
          font-size: 20px;
      }
      h2 {
        font-size: 20px;
        @include mobile-l {
          font-size: 24px;
        }
        @include desktop-s {
          font-size: 26px;
        }
        @include desktop {
          font-size: 28px;
        }
      }
    }

    .prices-explaination {
      font-size: 20px;
      margin-top: 0px;
      @include smaller-than-tablet{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        align-self: left;
        justify-self: left;
      }
      @include mobile-l {
        margin-top: 10px;
      }
      @include tablet {
        width: 270px;
        margin-top: 0px;
      }
      @include desktop-s {
        width: 350px;
      }
      @include desktop {
        width: 450px;
      }
    }
</style>