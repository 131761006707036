import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Concept from '../views/Concept.vue'
import Post from '../views/Post.vue'
import Prices from '../views/Prices.vue'
import Contact from '../views/Contact.vue'
import Cookies from '../views/Cookies.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/concept',
    name: 'Concept',
    component: Concept,
    props: true
  },
  {
    path: '/concept/:slug',
    name: 'Post',
    component: Post,
    props: true,
  },
  {
    path: '/prices',
    name: 'Prices',
    component: Prices,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: Cookies,
  },
  //redirect all wrong urls to home
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: Home
  }
]

const router = createRouter({
  
  scrollBehavior(to, from, savedPosition) {
    //scroll to same position if going back one page
    if (savedPosition) {
      return savedPosition
    }
    //scroll to top when going to a new page
    return { 
      top: 0, left: 0
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "nav-active-class",
})

export default router
