<template>
    <div class="container color shadow-bottom">
        <div class="height">
            <div class="center-container max-width-m center-home">
                <div class="circle"></div>
                <div class="home-container">
                    <div class="text-container-one">
                        <h2 class="nettside">Brosjyrer</h2>
                        <h2 class="nettside">Stil guide</h2>
                        <h2 class="nettside">Nettside</h2>
                        <h2 class="nettside">Presentasjoner</h2>
                        <h2 class="nettside">Forpakning &amp; emballasje</h2>
                    </div>
                    <div class="text-container-two">
                        <h2 class="nettside">Logo</h2>
                        <h2 class="nettside">Visittkort</h2>
                        <h2 class="nettside">Web design</h2>
                        <h2 class="nettside">UI UX</h2>
                        <h2 class="nettside">Brand story</h2>
                        <h2 class="nettside">Sosiale medier</h2>
                    </div>
                </div>
            </div>
            <div class="button-container">
                <router-link :to="{ name: 'Prices' }" class="button">
                    <button>
                        <div class="button-text">se priser</div>
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
    @import '../../styles/global.scss';

    .color {
        background-color: $warm-light;
        @include tablet {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    .height {
        height: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px;
        @include mobile-s {
            height: 650px;
        }
    }
    .home-container {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 10;
        max-width: 400px;
        @include tablet {
            flex-direction: row;
            max-width: 100%;
        }
    }
    .center-home {
        height: 90%;
    }
    .circle {
        position: absolute;
        height: 300px;
        width: 80%;
        border-radius: 30% 80% 30% 80%;
        background-color: $cream;
        box-shadow: 5px 6px 18px -6px $shadow;
        z-index: 1;
        @include mobile-l {
            height: 400px;
            width: 400px;
        }
        @include desktop {
            height: 500px;
            width: 500px;
        }
    }
    .text-container-one {
        text-align: center;
        margin: auto;
        max-width: 250px;
        @include mobile-l {
            max-width: 100%;
        }
        @include tablet {
            text-align: right;
            margin-right: 10px;
        }
        h2 {
            display: inline-block;
            margin-right: 10px;
        }
        h2:first-child { //Brosjyrer
            font-size: 22px;
            @include mobile-l {
                font-size: 26px;
            }
            @include tablet {
                font-size: 31px;
            }
            @include desktop {
                font-size: 46px;
            }
        }
        h2:nth-child(2) { //Stil guide
            font-size: 28px;
            @include mobile-l {
                font-size: 34px;
            }
            @include tablet {
                font-size: 39px;
            }
            @include desktop {
                font-size: 59px;
            }
        }
        h2:nth-child(3) { //Nettside
            font-size: 63px;
            margin-bottom: -22px;
            margin-top: -8px;
            @include mobile-l {
                font-size: 76px;
                margin-top: -16px;
            }
            @include tablet {
                font-size: 86px;
                margin-bottom: -20px;
                margin-top: -20px;
            }
            @include desktop {
                font-size: 126px;
                margin-bottom: -38px;
                margin-top: -25px;
            }
        }
        h2:nth-child(4) { //Presentasjoner
            font-size: 32px;
            @include mobile-l {
                font-size: 39px;
            }
            @include tablet {
                font-size: 44px;
                margin-top: -6px;
            }
            @include desktop {
                font-size: 64px;
            }
        }
        h2:last-child { //Forpakning og eballasje
            font-size: 20px;
            @include mobile-l {
                font-size: 24px;
            }
            @include tablet {
                font-size: 27px;
            }
            @include desktop {
                font-size: 40px;
            }
        }
    }
    .text-container-two {
        text-align: center;
        margin: auto;
        max-width: 250px;
        @include mobile-l {
            max-width: 100%;
        }
        @include tablet {
            text-align: left;
        }
        h2 {
            display: inline-block;
            margin-right: 10px;
        }
        h2:first-child { //Logo
            font-size: 32px;
            @include mobile-l {
                font-size: 45px;
            }
            @include tablet {
                font-size: 50px;
            }
            @include desktop {
                font-size: 70px;
            }
        }
        h2:nth-child(2) { //Visittkort
            font-size: 32px;
            @include mobile-l {
                font-size: 37px;
            }
            @include tablet {
                font-size: 41px;
            }
            @include desktop {
                font-size: 62px;
            }
        }
        h2:nth-child(3) { //Webdesign
            font-size: 28px;
            @include mobile-l {
                font-size: 35px;
            }
            @include tablet {
                font-size: 40px;
            }
            @include desktop {
                font-size: 60px;
            }
        }
        h2:nth-child(4) { //UI UX
            font-size: 27px;
            @include mobile-l {
                font-size: 34px;
            }
            @include tablet {
                font-size: 33px;
            }
            @include desktop {
                font-size: 53px;
            }
        }
        h2:nth-child(5) { //Brand story
            font-size: 40px;
            margin-top: -8px;
            @include mobile-l {
                font-size: 50px;
                margin-top: -5px;
            }
            @include tablet {
                font-size: 55px;
                margin-top: -10px;
            }
            @include desktop {
                font-size: 85px;
                margin-top: -15px;
            }
        }
        h2:last-child { // Sosiale Medier
            font-size: 32px;
            margin-top: -8px;
            @include mobile-l {
                font-size: 40px;
                margin-top: -14px;
            }
            @include tablet {
                font-size: 44px;
                margin-top: -10px;
            }
            @include desktop {
                font-size: 68px;
                margin-top: -20px;
            }
        }
    }

    .button-container {
        max-width: 1000px;
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        @include desktop {
            padding-left: 50px;
            justify-content: left;
        }
    }

</style>