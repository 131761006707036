<template>
  <Post />
  <Footer class="beige-bcg"/>
</template>

<script>
  import Post from '../components/concept/Post'
  import Footer from '../components/Footer'

  export default {
    components: { Post, Footer },
    name: 'Konsept - Lowenstad Branding',
    created(){
        document.title = "Konsept - Lowenstad Branding"
    }
  }
</script>

<style lang="scss" scoped>

</style>