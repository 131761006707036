import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";

createApp(App).use(router, VueGtag, {
    config: { 
        id: "G-T4DS2PRCFC", // Lowenstad website Tracking ID is: UA-159138762-1, MEASUREMENT ID is: G-T4DS2PRCFC
        params: {
          anonymize_ip: true
        }
    }
}).mount('#app');
