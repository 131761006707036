<template>
  <CookiesOne />
  <Footer class="beige-bcg"/>
</template>

<script>
  import CookiesOne from '../components/policy/CookiesOne'
  import Footer from '../components/Footer'

  export default {
    components: { CookiesOne, Footer },
    name: 'Lowenstad Branding Cookie Policy',
    created(){
        document.title = "Lowenstad Branding Cookie Policy"
    }
  }
</script>

<style lang="scss" scoped>

</style>