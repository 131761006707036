<template>

  <div class="top-container-sub color">
    <div class="center-horizontal max-width-m-l top-height-content-start">
      <div class="home-container">
            <h1>Konsept</h1>
            <div class="blog-container padding-bottom">
                <div v-for="post in posts" :key="post.slug" id="router-link">
                    <router-link :to="{ name: 'Post', params: { id: post.id, slug: post.slug, title: post.title, description: post.description, img: post.img }} ">
                        <div class="post-container" @mouseover="hover = post.id" @mouseleave="hover = false">
                            <img :src="post.img" alt="concept image" v-bind:class="{ onhover: hover === post.id}">
                            <p class="post-txt" v-show="hover === post.id">Les mer</p>
                        </div>
                    </router-link>
                </div>
            </div>     
        </div>
    </div>
  </div>
</template>

<script>
    import postdata from "./posts/postdata.js";
    
    export default {
        data() {
            return {
                posts: postdata,
                hover: false,
                hovering: false,
            }
        },
    }

</script>

<style lang="scss" scoped>
  @import '../../styles/global.scss';

    .home-container {
      height: auto;
      width: 100%;
      margin: auto;
    }

    .color {
      background: $cream;
    }

    img {
        height: auto;
        width: 100%;

    }
    .blog-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1px
    }
    .post-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    h1 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-weight: 100;
      @include mobile-l {
        font-size: 60px;
      }
    }
    #router-link {
        margin: 0;
        text-decoration: none;
        display: inline-block;
        height: auto;
        width: 33%;
        margin-bottom: -2.5px;
    }
    .post-txt {
        color: $black;
        font-size: 20px;
        max-width: 140px;
        position: absolute;
        background-color: $desert;
        border: 1px solid $shade;
        padding: 20px;
    }
    .onhover {
        opacity: 0.3;
        transition: opacity 0.8s ease;  
    }
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.8s;
    }
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    

</style>