<template>
<div class="footer-container">
    <div class="footer max-width-l">
        <div class="footer-title center">
            <router-link :to="{ name: 'Home' }"><h2>Lowenstad</h2></router-link>
            <p>By Tina Mari Pettersen</p>
            <p class="p-small">Copyright {{thisYear}}</p>
        </div>
        <div class="footer-info">
            <div>
                <router-link :to="{ name: 'About' }" autoscroll="true"><p>Om oss</p></router-link>
                <router-link :to="{ name: 'Concept' }"><p>Konsept</p></router-link>
            </div>
            <div>
                <router-link :to="{ name: 'Prices' }"><p>Priser</p></router-link>
                <router-link :to="{ name: 'Contact' }"><p>Kontakt</p></router-link>
            </div>
        </div>
        <div class="footer-icons">
            <a href="https://www.instagram.com/lowenstad/"><div class="insta"></div></a>
            <a href="https://www.facebook.com/Lowenstad-109894943871445/"><div class="face"></div></a>
        </div>
        <div class="footer-logo"></div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return{
            thisYear: new Date().getFullYear()
        }
    }, 
}
</script>

<style lang="scss" scoped>

    @import '../styles/global.scss';

    p {
        color: $black;
    }

    h2 {
        color: $black;
        &:hover {
            color: $dark;
        }
    }

    .footer-container {
        display: flex;
        justify-content: center;
        background-color: $cream;
    }

    .footer {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 220px;
        overflow: hidden;
        display: grid;
        grid-template-rows: 40% 30% 30%;
        grid-template-columns: 65% 60%;
        column-gap: 10px;
        row-gap: 10px;
        padding-top: 10px;
        padding-bottom: 20px;
        h2 {
            font-size: 28px;
            line-height: 38px;
        }
        p {
            font-size: 16px;
        }
        @include mobile-l {
            grid-template-columns: 35% 35% 35%;
            grid-template-rows: 50% 50%;
        }
        @include tablet {
            height: 200px;
            grid-template-columns: 30% 20% 20% 30%;
            grid-template-rows: 100%;
            align-items: center;
            padding-bottom: 0px;
            h2 {
                font-size: 42px;
                line-height: 52px;
            }
            p {
                font-size: 20px;
            }
        }
    }

    .footer-title {
        grid-column-start: 1;
        grid-row-start: 1;
        justify-self: center;
        @include mobile-l {
            grid-row-end: 4;
            margin-bottom: 20px;
        }
    }
    .footer-info {
        grid-column-start: 1;
        grid-row-start: 2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: auto;
        gap: 16px;
        margin: auto;
        @include mobile-l {
            margin: 0;
            display: grid;
            grid-template-columns: auto auto;
            grid-row-start: 1;
            grid-row-end: 1;
            grid-column-start: 2;
            align-self: end;
            justify-self: center;
        }
        @include tablet {
            margin: auto;
            width: auto;
            height: 50%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 15px;
        }
        @include desktop-s {
            gap: 24px;
        }
        p {
            color: $black;
            font-size: 16px;
            text-align: left;
            &:hover {
                color: $dark;
            }
            @include tablet {
                font-size: 18px;
            }
            @include desktop-s {
                font-size: 20px;
            }
        }
    }

    .footer-icons {
        grid-column-start: 1;
        grid-row-start: 3;
        height: 38px;
        @include mobile-l {
            grid-column-start: 2;
            grid-row-start: 2;
            height: 42px;
        }
        @include tablet {
            grid-row-start: 1;
            grid-column-start: 3;
            height: 54px;
        }
    }

    .nav-active-class p{
        color: $dark-beige;
    }
    .footer-logo {
        background-image: url('../assets/img/footer/logo-some-white.svg');
        background-repeat: no-repeat;
        height: 220px;
        width: 210px;
        grid-column-start: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        margin-bottom: -100px;
        @include mobile-l {
            grid-column-start: 3;
            grid-row-start: 1;
            height: 280px;
            width: auto;
        }
        @include tablet {
            grid-row-start: 1;
            grid-column-start: 4;
        }
    }

</style>