<template>
  <div class="top-container-sub color">
    <div class="center-horizontal max-width-m top-height-content-start">
      <div class="home-container">
            <h1>Digitalt design</h1>
            <div class="text-container">
                  <p class="text-one">Lowenstad er et designbyrå basert i Oslo som jobber med utvikling av merkevare, 
                      presentasjon og nettsider.
                  </p>
                  <div class="palmtree" role="img" aria-label="Palmtree image"></div>
                  <p class="text-two">
                      Vi ønsker å hjelpe små- og mellomstore bedrifter med
                      å kommunisere sitt budskap og fremstille sine produkter og tjenester på en estetisk 
                      og overbevisende måte. Dette med et mål om å vise enhver bedrift fra sin beste side, 
                      lage en helhetlig bedriftsprofil og gjøre kundereisen så god som mulig.
                  </p>

                  <h2>Visuell &amp; aktuell</h2>
                  <p>Merkevarebygging handler om å skape tillit og vekke interesse. Å skape en identitet man kan 
                    relatere seg til og som reflekterer bedriftens essens. Da er det viktig at en bedrift oppfattes 
                    likt på alle plattformer og at innholdet virker oppdatert og i tiden. 
                  </p>
                  <p><br><br>
                      Ny teknologi er forventet av dagens kunder og komplisert informasjon skal 
                      kommuniseres på en enkel og forstålig måte. Det er her vår kompetanse innen 
                      digitale løsninger, brukeropplevelse og design kommer inn.
                </p>
                <router-link :to="{ name: 'Contact' }" class="button about-one-button">
                  <button>
                    <div class="button-text">kontakt oss</div>
                  </button>
                </router-link>
                
            </div>
        </div>
    </div>
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>
  @import '../../styles/global.scss';

    .height {
      padding-top: 60px;
      padding-bottom: 60px;
      @include mobile-l {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }

    .home-container {
      height: auto;
      width: 100%;
      margin: auto;
    }

    .color {
      background: $cream;
    }

    h1 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 30px;
      @include mobile-l {
        font-size: 60px;
        margin-right: 0vw;
      }
      @include tablet {
        margin-right: 22vw;
      }
      @include desktop {
          margin-right: 15vw;
      }
    }

    h2 {
      margin-bottom: 10px;
      font-size: 41px;
      word-spacing: 200px;
      text-align: center;
      color: $dark;
      @include mobile {
        word-spacing: 0px;
      }
      @include tablet {
        font-size: 46px;
      }
    }

    .about-one-button button{
      margin: auto;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .text-container {
        height: 100%;
        width: 100%;
        display: inline;
        //width: 800px;
        overflow: hidden;
    }

    .palmtree {
      background-image: url('../../assets/img/about/palmtree.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-right: -40vw;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 50px;
      display: inline;
      float: right;
      align-self: center;
      justify-self: center;
      height: 630px;
      width: 250px;
      @include mobile {
        margin-right: -35vw;
      }
      @include mobile-l {
        margin-right: -20vw;
        margin-left: 15px;
        margin-top: 15px;
      }
      @include tablet-s {
        margin-right: -10vw;
      }
      @include tablet {
        margin-right: 0vw;
        margin-top: 15px;
        margin-left: 15px;
      }
      @include desktop {
        height: 600px;
        width: 238px;
      }
    }

    .text-two {
      margin-bottom: 30px;
      @include desktop {
        margin-bottom: 50px;
      }
    }

</style>