<template>
<div class="cookie-container" v-if="!isHidden">
    <div class="cookie-content max-width-l">
        <p class="p-small">
            Cookies brukes på denne nettsiden for analysering og forbedring av brukeropplevelsen. 
            Du kan hindre at informasjonskapsler blir lagret ved å endre innstillingene i din nettleser. Les mer om vår cookie policy <router-link :to="{ name: 'Cookies' }" class="underline">her</router-link>.
        </p>
        <button class="small-button" @click="isHidden = true">ok</button>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
        isHidden: false,
        };
    },
}
</script>

<style lang="scss" scoped>

    @import '../styles/global.scss';

    .cookie-container {
        position: fixed;
        background-color: $warm-light;
        box-shadow: 5px -6px 14px -12px $dark;
        width: 100%;
        height: 165px;
        z-index: 10000;
        bottom: 0;
        left: 0;
        right: 0;
        @include mobile {
            height: 130px;
        }
        @include mobile-l {
            height: 95px;
        }
        @include tablet {
            height: 75px;
        }
        @include desktop-s {
            height: 65px;
        }
    }

    .cookie-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 100%;
        width: 80%;
        margin: auto;
        @include mobile {
            gap: 26px;
            flex-direction: row;
            justify-content: space-between;
            p {
                flex: 3;
                max-width: 790px;
            }
            button {
                flex: 1;
            }
        }
        @include tablet{
            gap: 30px;
        }
    }

</style>