<template>
  <div class="top-container-main color-change">
    <div class="center-container max-width-m top-height-center-content">
      <div class="home-container">
        <h1 class="nettside">Nettside</h1>
        <h1 class="merkevare ">Merkevare</h1>
        <h1 class="print">Print</h1>
        <div class="tiger-logo scale" role="img" aria-label="Lowenstad Logo Lion"></div>
      </div>
    </div>
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>
    @import '../../styles/global.scss';
    
    .tiger-logo {
      background-image: url('../../assets/img/logo-lion-shade.svg');
      background-repeat: no-repeat;
      background-position: center;
      grid-column-start: 2;
      grid-row-start: 2;
      align-self: center;
      justify-self: center;
      height: 50vh;
      width: 25vh;
      margin-top: 40px;
      z-index: 10;
      @include mobile-s {
        width: 40vh;
      }
      @include tablet {
        height: 60vh;
        width: 60vh;
        margin-top: 50px;
        margin-left: 50px;
      }
    }
    .home-container {
        height: 50vh;
        width: 100%;
        display: grid;
        grid-template-rows: 33% 33% 33%;
        grid-template-columns: 33.3% 33.3% 33.3%;
        row-gap: 10px;
        align-items: center;
        h1 {
          font-size: 40px;
          font-weight: 400;
          text-transform: uppercase;
          z-index: 50;
          @include mobile-l {
            font-size: 60px;
          }
          @include tablet {
            font-size: 90px;
            font-weight: 200;
          }
        }
    }
    .nettside {
        grid-column-start: 1;
        grid-row-start: 1;
        align-self: start;
        justify-self: start;
    }
    .merkevare {
      grid-column-start: 2;
      grid-row-start: 2;
      align-self: center;
      justify-self: center;
    }
    .print {
      grid-column-start: 3;
      grid-row-start: 3;
      align-self: end;
      justify-self: end;
    }
 


    .nettside {
      animation: fadeIn 10s;
    }
    .merkevare {
      animation: fadeIn 3s;
    }
    .print {
      animation: fadeIn 6s;
    }
    @keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }

</style>