<template>
    <div class="body-container">
      <Header />
      <CookieBanner />
      <router-view/>
    </div>
</template>

<script>
  import Header from './components/Header'
  import CookieBanner from './components/CookieBanner'

  export default {
    components: { Header, CookieBanner },

  }


</script>

<style lang="scss">
  @import './styles/global.scss';
  
</style>
