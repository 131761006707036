<template>

    <div v-for="brandPrice in BrandPrices" :key="brandPrice.product" class="prices-container">
        <h2 class="prices-product"> {{ brandPrice.product }} </h2>
        <p class="prices-explaination"> {{ brandPrice.explaination }} </p>
        <div class="prices-price">
            <p>fra</p>
            <h2> {{ brandPrice.price }} </h2>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                BrandPrices: [
                    { 
                        product: 'Brand Story',
                        explaination: 'Definerer bedriftens identitet. En gjennomgang av konsept, motto, visjon, essens, personlighet, verdier, posisjon og kundegruppe.',
                        price: '8 500kr',
                    },
                    { 
                        product: 'Stil Guide',
                        explaination: 'Inneholder fonter, farger, ikoner og stiltype.',
                        price: '7 500kr',
                    },
                    { 
                        product: 'Sosiale medier',
                        explaination: 'Strategi på hvordan din bedrift skal markedsføre seg gjennom Facebook og Instagram, pluss stilguide inkludert 12 innlegg.',
                        price: '10 800kr',
                    },
                ]
            }
        }
    }

</script>