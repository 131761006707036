<template>
  <HomeOne />
  <HomeTwo />
  <HomeThree />
  <HomeFour />
  <Footer class="cream-bcg"/>
</template>

<script>
  import HomeOne from '../components/home/HomeOne'
  import HomeTwo from '../components/home/HomeTwo'
  import HomeThree from '../components/home/HomeThree'
  import HomeFour from '../components/home/HomeFour'
  import Footer from '../components/Footer'

  export default {
    components: { HomeOne, HomeTwo, HomeThree, HomeFour, Footer },

    name: 'Lowenstad Branding',
    created(){
        document.title = "Lowenstad Branding"
    }
  }
</script>

<style lang="scss">

</style>