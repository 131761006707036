<template>
  <PricesOne />
  <Footer class="beige-bcg"/>
</template>

<script>
  import PricesOne from '../components/prices/PricesOne'
  import Footer from '../components/Footer'

  export default {
    components: { PricesOne, Footer },
    name: 'Priser for Lowenstad Branding',
    created(){
        document.title = "Priser for Lowenstad Branding"
    }
  }
</script>

<style lang="scss" scoped>

</style>