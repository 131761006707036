<template>

     <div v-for="graphicPrice in GraphicPrices" :key="graphicPrice.product" class="prices-container">
        <h2 class="prices-product"> {{ graphicPrice.product }} </h2>
        <p class="prices-explaination"> {{ graphicPrice.explaination }} </p>
        <div class="prices-price">
            <p>fra</p>
            <h2> {{ graphicPrice.price }} </h2>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                GraphicPrices: [
                    { 
                        product: 'Logo',
                        explaination: 'Skisser etter utvalgt stil, videre-utvikling og ferdigstilling av logo i relevante formater og farger.',
                        price: '6 800kr',
                    },
                    { 
                        product: 'Presentasjon',
                        explaination: 'En grafisk fremstilt presentasjon om ide, bedrift eller produkt.',
                        price: '5 500kr',
                    },
                    { 
                        product: 'Brosjyre',
                        explaination: 'En grafisk fremstilt brosjyre om ide, bedrift eller produkt.',
                        price: '6 500kr',
                    },
                    { 
                        product: 'Visittkort',
                        explaination: 'Et grafisk fremstilt visittkort med kontaktinformasjon og logo.',
                        price: '4 500kr',
                    },
                    { 
                        product: 'Flyer',
                        explaination: 'En grafisk fremstilt side som forteller om et produkt, en tjeneste eller et event.',
                        price: '4 800kr',
                    },
                    { 
                        product: 'Emballasje',
                        explaination: 'Design av emballasje og forpakning for et produkt.',
                        price: '5 800kr',
                    },
                ]
            }
        }
    }

</script>