<template>
  <div class="top-container-sub color">
    <div class="center-horizontal max-width-m top-height-content-start">
      <div class="home-container">
          <div class="post-container">
              <div class="post-img-container">
                <img :src="img" alt="concept image"/>
              </div>

              <div class="post-txt-container">
                <div v-if="render" id="render"></div>
                <h2 class="margin">{{ title }}</h2>
                <p class="margin">{{ description }}</p>
                <p @click="back" class="link margin">Tilbake</p>
                <div class="center-mobile">
                    <router-link :to="{ name: 'Contact' }" class="button">
                        <button>
                            <div class="button-text">kontakt oss</div>
                        </button>
                    </router-link>
                </div>
              </div>
          </div>

        </div>
    </div>
  </div>
</template>

<script>
    import postdata from "./posts/postdata.js";

    export default {
        name: "Post",
        data() {
            return {
                render: false,
                id: this.$route.params.id,
                slug: this.$route.params.slug,
                title: this.$route.params.title,
                description: this.$route.params.description,
                img: this.$route.params.img,
                route: this.$route.params,
            }
        },
        methods: {
            //back button
            back() {
                this.$router.go(-1)
            },
        },
        
        created() {
            //Check that url extension is legit and are holding a slug that exists, else send to home page
            let allPostNames = postdata.map(slugs => slugs.slug); //creates a new array of all the slugs
            let doesPageExist = allPostNames.includes(this.slug);
            if ( !doesPageExist ) {
                this.$router.push({
                    name: "Home",
                });
            } 
            //check if post have content if not send to Concept page, if it has add the id of render
            if (typeof this.description == 'undefined') {
                this.render = false
                this.$router.push({
                    name: "Concept",
                });
            } else this.render = true
        },
    }

</script>

<style lang="scss" scoped>
  @import '../../styles/global.scss';

    #render {
        background-color: green !important;
    }

    .home-container {
      height: auto;
      width: 100%;
      margin: auto;
      padding-bottom: 100px;
    }

    .color {
      background: $cream;
    }
    p {
        white-space: pre-line;
    }
    h2 {
    font-size: 30px;
    @include mobile-l {
        font-size: 38px;
        line-height: 38px;
      }
    }

    img {
        border: 2px solid $shadow;
        max-height: 100%;
        max-width: 100%;
        @include mobile-l {
            max-height: 400px;
            max-width: 400px;
        }
        @include tablet-l {
            max-height: 400px;
            max-width: 400px;
        }
        @include desktop-s {
            max-height: 470px;
            max-width: 470px;
        }
    }
    .post-img-container {
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin-bottom: 30px;
        @include tablet-l {
            display: inline-block;
            float: right;
            margin-left: 30px;
            max-height: 400px;
            max-width: 400px;
        }
        @include desktop-s {
            max-height: 470px;
            max-width: 470px;
        }
    }

</style>