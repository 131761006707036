<template>
    <div class="center-home">
        <div class="header max-width-l">
            <div class="logo">
                <router-link :to="{ name: 'Home' }">
                    <img src="../assets/navbar/logo-main-branding.svg" alt="Branding logo">
                </router-link>
            </div>

            <div class="navbar">
                <div @click="toggleMenu" class="nav-button"  v-bind:class="{ btnwhite: showMenu }">
                    <span v-if="!showMenu"><img src="../assets/navbar/menu.svg" alt="open menu" class="nav-icon"></span>
                    <span v-else><img src="../assets/navbar/exit-menu.svg" alt="close menu" class="nav-icon"></span>
                </div>
            </div>
        </div>
        <div class="header-container" v-bind:class="{ fullscreen: showMenu }">
            <nav v-if="showMenu" class="nav-items">
                <router-link :to="{ name: 'Home' }" @click="toggleMenu" class="nav-item" ><h2>forside</h2></router-link>
                <router-link :to="{ name: 'About' }" @click="toggleMenu" class="nav-item"><h2>om oss</h2></router-link>
                <router-link :to="{ name: 'Concept' }" @click="toggleMenu" class="nav-item"><h2>Konsept</h2></router-link>
                <router-link :to="{ name: 'Prices' }" @click="toggleMenu" class="nav-item"><h2>priser</h2></router-link>
                <router-link :to="{ name: 'Contact' }" @click="toggleMenu" class="nav-item"><h2>kontakt</h2></router-link>
            </nav>
        </div>
    </div>
        
</template>

<script>
export default {
    data() {
        return{
            showMenu: false
        }
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu
            if (this.showMenu) {
                document.body.classList.add("lock-scroll");
                document.documentElement.classList.add("lock-scroll");
            } else {
                document.body.classList.remove("lock-scroll");
                document.documentElement.classList.remove("lock-scroll");
            }
        }
    }
}

</script>

<style lang="scss" scoped>

    @import '../styles/global.scss';

    .center-home {
        display: flex;
        justify-content: center;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin: auto;
        margin-top: 32px;
        margin-bottom: 42px;
        position: absolute;
        z-index: 1000;
        @include tablet {
            margin-top: 52px;
        }
        @include desktop-s {
            margin-top: 82px;
        }
    }
    .logo {
        flex: 1.5;
    }
    .logo img {
        max-height: 75px;
        @include tablet {
            max-height: 100px;
        }
    }
    .navbar {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .nav-button {
        height: 40px;
        width: 40px;
        //position: fixed;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 5px 6px 18px -6px $black;
        border: none;
        margin-left: auto;
        right: 10%;
        &:hover {
            background: $hover;
            box-shadow: 5px 6px 18px -6px $dark;
        }
        @include mobile-l {
            height: 50px;
            width: 50px;
        }
    }
    .nav-button span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .btnwhite {
        background-color: $white;
    }
    .nav-icon {
        height: 50%;
        width: 50%;
    }
    .nav-items {
        display: flex;
        flex-direction: column;
        text-align: right;
        width: 80%;
        max-width: 1280px;
        margin: auto;
        margin-top: 200px;
        h2 {
            font-size: 28px;
            max-width: 250px;
            float: right;
            text-transform: uppercase;
            @include mobile {
                font-size: 32px;
            }
            @include tablet {
                font-size: 38px;
            }
            &:hover {
                color: $shadow;
                font-weight: 800;
            }
        }
    }
    .nav-active-class h2{
        color: $shadow;
        font-weight: 800;
    }
    .nav-active-class h2::before {
        content: "\2022";
        color: inherit;
        padding-right: 15px;
        position: relative;
        
    }
    .nav-item {
        margin-bottom: 42px;
        @include tablet {
            margin-bottom: 46px;
        }
    }
    .header-container {
        background: linear-gradient(90deg, $warm-light 40%, $cream 0%);
        box-shadow: -10px 0px 32px 1px $desert;
        height: 100vh;
        width: 100vw;
        position: fixed;
        transform-origin: top;
        overflow: hidden;
        transform: translateX(+120%);
        transition: transform .4s ease-in-out;
        z-index: 100;
        &.fullscreen {
            transform: translateY(0);
        }
    }

</style>