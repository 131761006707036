<template>

    <div v-for="webPrice in WebPrices" :key="webPrice.product" class="prices-container">
        <h2 class="prices-product"> {{ webPrice.product }} </h2>
        <p class="prices-explaination"> {{ webPrice.explaination }} </p>
        <div class="prices-price">
            <p>fra</p>
            <h2> {{ webPrice.price }} </h2>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                WebPrices: [
                    { 
                        product: 'Landingsside',
                        explaination: 'En landingsside er en enkelt side som forteller om et produkt, en tjeneste eller et event.',
                        price: '12 500kr',
                    },
                    { 
                        product: 'One-pager',
                        explaination: 'Oppsett av en nettside bestående av en enkelt side som gir informasjon om et firma.',
                        price: '22 500kr',
                    },
                    { 
                        product: 'Multi-page',
                        explaination: 'En komplett nettside med flere sider som forteller om et firma og deres produkter eller tjenester.',
                        price: '32 000kr',
                    },
                    { 
                        product: 'Nettbutikk',
                        explaination: 'En komplett nettside med informasjon om firma med nettbutikk for salg av produkter.',
                        price: '46 000kr',
                    },
                    { 
                        product: 'Web Design',
                        explaination: 'Design, utforming og layout for nettside.',
                        price: '12 800kr',
                    },
                ]
            }
        }
    }

</script>