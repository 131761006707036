<template>
  <div class="container color">
    <div class="center-container max-width-m height">
        <div class="home-container">
          <div class="logo">
            <img src="../../assets/img/logo-crest.svg" alt="Logo icon">
          </div>
          <h2 class="title ">Dine behov visualisert gjennom digitale løsninger</h2>
          <p class="text p-large">En klar bedriftsprofil er essensielt for å skape kundeforståelse og troverdighet. Få en komplett merkeidentitet som reflekterer deres produkt, verdier og målsetning - gjennom alle plattformer.</p>
          <router-link :to="{ name: 'About' }" class="button">
            <button>
              <div class="button-text">mer om oss</div>
            </button>
          </router-link>
        </div>
    </div>
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>
  @import '../../styles/global.scss';

    .color {
      background: $warm-light;
    }

    .height {
      padding-top: 60px;
      padding-bottom: 60px;
      @include mobile-l {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }

    .home-container {
      height: auto;
      width: 100%;
      display: grid;
      grid-template-rows: auto auto auto auto;
      grid-template-columns: auto auto;
      align-items: center;
      column-gap: 16px;
      @include mobile-m {
        grid-template-rows: auto auto auto;
        grid-template-columns: 30% 60%;
      }
      @include tablet {
        row-gap: 20px;
        grid-template-rows: auto auto auto;
        grid-template-columns: 30% 60%;
        column-gap: 40px;
      }
      @include desktop-s {
        row-gap: 10px;
      }
      h2 {
        font-size: 25px;
        text-align: center;
        @include mobile-m {
          text-align: left;
        }
        @include tablet {
          font-size: 32px;
        }
        @include desktop-s {
          font-size: 36px;
        }
        @include desktop {
          font-size: 42px;
        }
      }
    }
    .logo {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 2;
      align-self: center;
      justify-self: start;
      margin: auto;
      padding-top: 26px;
      @include mobile-m {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        padding-top: 0px;
      }
      @include mobile-l {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 3;
        justify-self: start;
      }
      @include tablet {
        justify-self: center;
        grid-row-end: 4;
      }
      img {
        width: 100%;
        height: 200px;
        @include mobile-m {
          height: auto;
        }
      }
    }

    .title {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      align-self: center;
      justify-self: end;
      @include mobile-m {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
      }
      @include mobile-l {
        grid-column-start: 2;
        grid-column-end: 4;
        justify-self: end;
        align-self: end;
      }
    }
    .text {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 3;
      align-self: center;
      justify-self: center;
      padding-top: 26px;
      padding-bottom: 26px;
      @include mobile-l {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 2;
        justify-self: start;
      }
    }
    .button {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 4;
      align-self: center;
      justify-self: center;
      @include tablet {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 3;
        justify-self: start;
        align-self: start;
      }
    }

</style>