<template>
  <div class="top-container-sub color">
    <div class="center-horizontal max-width-l top-height-content-start">
      <div class="home-container">

        <div class="contact-container">

          <h1>Kontakt oss</h1>

          <div class="contact-info">

              <p class="text-one">
                Tina Mari Pettersen <br>
                <a href="mailto:tina@lowenstad.com">tina@lowenstad.com</a> <br>
                <a href="tel:004792662824">+47 92 66 28 24</a>
              </p>

              <div class="footer-icons">
                  <a href="https://www.instagram.com/lowenstad/"><div class="insta"></div></a>
                  <a href="https://www.facebook.com/Lowenstad-109894943871445/"><div class="face"></div></a>
              </div>

          </div>

        </div>
                
      </div>
    </div>
  </div>
</template>

<script>
  
</script>

<style lang="scss" scoped>
  @import '../../styles/global.scss';

    .height {
      padding-top: 60px;
      padding-bottom: 60px;
      @include mobile-l {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }

    .home-container {
      height: auto;
      width: 100%;
      margin: auto;
      height: 60vh;
      margin-bottom: 10vh;
      padding: 0px 50px 0px 50px;
      background-color: $warm-light;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 5px 6px 18px -6px $shadow
    }

    .contact-container {
      width: 400px;
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mobile-l {
        flex-direction: row;
      }
    }

    .color {
      background: $cream;
    }

    h1 {
      font-size: 36px;
      text-align: center;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-weight: 600;
      @include mobile-l {
        font-weight: 100;
        font-size: 60px;
        margin-bottom: 50px;
        text-align: left;
      }
    }

    .text-one {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 28px;
      @include mobile-l {
        text-align: left;
        margin-bottom: 0px;
      }
      @include desktop-s {
        width: 68%;
      }
    }

    .text-container {
        height: 100%;
        width: 100%;
        display: inline;
        //width: 800px;
        overflow: hidden;
    }

</style>