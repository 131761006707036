<template>
  <div class="container color">
    <div class="full-container height">
        <h2> Utvalgte prosjekter </h2>
        <div class="carusel-container">
          <div class="inner-carusel-container">
              <Project />
          </div>
        </div>
        <router-link :to="{ name: 'Contact' }" class="button">
            <button>
              <div class="button-text">kontakt oss</div>
            </button>
        </router-link>
    </div>
  </div>
</template>

<script>
    import Project from './homeContent/Project'

    export default {
      components: { Project }
    }

</script>

<style lang="scss" scoped>
    @import '../../styles/global.scss';

    .height {
      padding-top: 50px;
      padding-bottom: 50px;
      flex-direction: column;
      h2 {
        font-size: 50px;
        color: $dark;
        text-align: center;
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .color {
      background: $cream;
    }

    .carusel-container {
      width: 100%;
      display:block;
      overflow: hidden;
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
    }

    .inner-carusel-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      gap: 50px;
      overflow-x: scroll;
      overflow-y: hidden;
      padding-left: 35px;
    }

    //right side padding of scroll container
    .inner-carusel-container::after {
      content: '';
      padding-right: 0.2px;
    }

    .inner-carusel-container::-webkit-scrollbar {
      visibility: visible;
      max-height: 10px;
      max-width: 10px;
      height: 10px;
      width: 10px;
    }
    .inner-carusel-container::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $shadow;
    }
    .inner-carusel-container::-webkit-scrollbar-track {
      background: transparent;
      margin-left: 35px;
      margin-right: 35px;
      @include tablet {
        margin-left: 40px;
        margin-right: 40px;
      }
    }

</style>