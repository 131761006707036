<template>

    <div class="project">
        <a href="https://www.bconcept.no/" target="_blank">
            <div class="project-img-container">
                <div class="project-img bconcept" role="img" aria-label="BConcept Logo"> </div>
            </div>
        </a>
        <div class="project-text-container"> 
            <p>
                BConcept ønsket en komplett bedriftsidentitet. 
                Lowenstad utviklet logo, brand story, stil-guide, visittkort, email-oppsett og nettside for BConcept. 
                Se nettsiden her: <a href="https://www.bconcept.no/" target="_blank" class="link"> bconcept.no </a> 
            </p>
        </div>
    </div>

    <div class="project">
        <div class="project-img-container">
            <div class="project-img aanestad" role="img" aria-label="Aanestad Logo"> 
            </div>
        </div>
        <div class="project-text-container"> 
            <p>
                Aanestad AS er et malerfirma som ville ha en logo som var tydelig,  estetisk og klassisk
                men fortsatt i tiden. Ønsket var å ligge nærmere interiør-bransjen i stil enn byggevare.
            </p> 
        </div>
    </div>

    <div class="project">
        <a href="https://boru.no/" target="_blank">
            <div class="project-img-container">
                <div class="project-img boru" role="img" aria-label="Boru Logo">  </div>
            </div>
        </a>
        <div class="project-text-container"> 
            <p>
                Boru Eiendom eier og leier ut boliger og næringslokaler i Vestfold og Viken og ønsket en
                fullstendig bedriftsprofil. Lowenstad utviklet logo, stil-guide og nettside. 
                Se nettsiden her: <a href="https://boru.no/" target="_blank" class="link"> boru.no </a>
            </p>
        </div>
    </div>

    <div class="project">
        <a href="https://www.profag.no/" target="_blank">
            <div class="project-img-container">
                <div class="project-img profag" role="img" aria-label="Profag Logo">  </div>
            </div>
        </a>
        <div class="project-text-container">  
            <p>
                ProFag selger gulvrelaterte produkter til byggevare-sektoren. De ønsket en ny logo som var
                 moderne, robust og stilren. Fargene de brukte ble også oppdatert og ble implementert på 
                 nettsiden deres: <a href="https://www.profag.no/" target="_blank" class="link"> profag.no </a>
            </p>
        </div>
    </div>

</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
    @import '../../../styles/global.scss';

    .project {
        border: 2px solid $shadow;
        border-radius: 150px 150px 0px 0px;
        background-color: $warm-light;
        height: 500px;
        min-width: 80vw;
        max-width: 80vw;
        width: 80vw;
        margin-bottom: 30px;
        margin-top: 30px;
        @include mobile {
            min-width: 270px;
        }
        @include desktop {
            min-width: 280px;
            width: 280px;
        }
    }

    .project-img-container {
        height: 220px;
        width: 100%;
        background-color: $cream;
        border-radius: 150px 150px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            object-fit: cover;
        }
        @include mobile-s {
            height: 250px;
        }
    }
    .project-img {
        background-repeat: no-repeat;
        background-position: center;
    }
    .bconcept {
        background-image: url('../../../assets/img/home/Bconcept.svg');
        height: 60%;
        width: 60%;
        margin-top: 20px;
    }
    .aanestad {
        background-image: url('../../../assets/img/home/aanestad.svg');
        height: 70%;
        width: 70%;
    }
    .boru {
        background-image: url('../../../assets/img/home/boru.svg');
        height: 55%;
        width: 55%;
        margin-top: 30px;
    }
    .profag {
        background-image: url('../../../assets/img/home/Profag.svg');
        height: 45%;
        width: 45%;
        margin-top: 10px;
    }
    .project-text-container {
        margin: auto;
        padding: 20px;
        text-align: center;
        p {
            text-align: left;
        }
    }
</style>