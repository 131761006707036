<template>
  <AboutOne />
  <Footer class="beige-bcg"/>
</template>

<script>
  import AboutOne from '../components/about-us/AboutOne'
  import Footer from '../components/Footer'

  export default {
    components: { AboutOne, Footer },
    name: 'Om Lowenstad Branding',
    created(){
        document.title = "Om Lowenstad Branding"
    }
  }
</script>

<style lang="scss" scoped>

</style>